<template>
  <div>
    <img
      :src="url"
      v-viewer
      alt=""
      :style="{ width, height }"
      style="max-width: 100%; object-fit: cover"
    />
  </div>
</template>

<script>
export default {
  name: "ImageViewer",
  props: {
    url: {
      type: String,
      default: () => "",
    },
    width: {
      type: String,
      default: () => "auto",
    },
    height: {
      type: String,
      default: () => "60px",
    },
  },
};
</script>

<style scoped></style>
